<template>
  <div>
    <div class="flex-col section">
  <div class="flex-col group">
    <div class="section_2"></div>
    <div class="group_2">
      <p @click="sets('ionex')">
        <img
        v-show="selected=='ionex'"
    class="image"
    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16638314988196539146.png"
  />
      <span class="font_1">爱玩标准版</span>
      <span class="font_1">
        i-OneX
        <br />
      </span>
    </p>
    <p @click="sets('ionexs')">
      <img
        v-show="selected=='ionexs'"
    class="image"
    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16638314988196539146.png"
  />
      <span class="font_2">爱玩运动版</span>
      <span class="font_2">
        i-OneXS
        <br />
      </span>
    </p>
    <p>
      
      <span class="font_3">爱玩进阶版</span>
      <span class="font_3">
        i-One Plus
        <br />
      </span>
    </p>
    <p @click="sets('ilikes')">
      <img
        v-show="selected=='ilikes'"
    class="image"
    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16638314988196539146.png"
  />
      <span class="font_2">智能复古电摩</span>
      <span class="font_2">
        i-Like
        <br />
      </span>
    </p>
    <p  @click="sets('s7')">
      <img
     
        v-show="selected=='s7'"
    class="image"
    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16638314988196539146.png"
  />
      <span class="font_2">赛道级电摩</span>
      <span class="font_2">
        S7
        <br />
      </span>
    </p>
    <p @click="sets('f9')">
      <span class="font_2">高性能仿赛街跑</span>
      <span class="font_2">F9</span>
    </p>
    </div>
  </div>
  
</div>
  </div>
</template>

<script>
export default {
  name: 'Vue3Tab',
  props:["selected"],
  data() {
    return {
      
    };
  },

  mounted() {
    
  },

  methods: {
    sets(i){
      console.log(i)
      this.$emit('input',i)
    }
  },
};
</script>

<style scoped lang="scss">
.section {
 
  padding: 32px 0 27px;
  background-color: #ffffff;
  box-shadow: 0px 0px 15.52px 0.48px #0900013b;
  border-radius: 10px;
  width: 360px;
  position: relative;
 justify-content: center;
  
}
.group {
  padding-bottom: 251px;
  width: 360px;
  position: relative;
}
.section_2 {
  
  height: 50px;
 
}
.group_2 {
  width: 100%;
  position: absolute;
  left: 0;
  
  bottom: 0;
}
.group_2 p{
  padding-left: 44px;
  position: relative;
}
.group_2 p:not(:nth-child(3)):hover{
  background-color: #4f92f8;
 
  span{
    color: #ffffff;
  }
}
.font_1 {
  font-size: 24px;
 
  line-height: 50px;
  color: #000;
}
.font_2 {
  font-size: 24px;
 
  line-height: 50px;
  color: #000;
}
.font_3 {
  font-size: 24px;
 
  line-height: 50px;
  color: #8d8d8d;
}
.image {
  width: 16px;
  height: 11px;
  position: absolute;
  left: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
}
</style>