<template>
   <div class="flex-col page">
      <div class="flex-col items-center space-y-18" style="margin-bottom: 10.4rem;">
        <span class="text">车型对比</span>
        <span class="font_1 text_2">选择您的专属车型</span>
      </div>
    <div class="flex-row" style="margin: 0 auto;">
      <constart :selected="car" :sec="false"></constart>
      <constart selected="ionexs" :sec="true"></constart>  
    </div>
      
  </div>
</template>

<script>
import constart from "./constart.vue"
export default {
  name: 'Vue3Index',

  data() {
    return {
      
    };
  },
  props:['car'],
  components:{
    constart
  },
  mounted() {
    
  },

  methods: {
    
  },
};
</script>

<style scoped>
.page {
  padding: 149px 0 366px ;
  background-color: #f2f2f2 !important;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.group {
  align-self: flex-start;
  /* width: 687px; */
}

.space-y-72>*:not(:first-child) {
  margin-top: 72px;
}

.group_2 {
  padding-left: 143px;
  position: relative;
}

.space-y-104>*:not(:first-child) {
  margin-top: 104px;
}

.space-y-18>*:not(:first-child) {
  margin-top: 18px;
}

.text {
  color: #000;
  font-size: 48px;
  
  font-weight: 700;
  line-height: 44px;
}

.font_1 {
  font-size: 24px;
  
  line-height: 23px;
  font-weight: 700;
  color: #000;
}

.text_2 {
  margin-right: 3px;
  font-weight: unset;
}

.section {
  padding: 23px 0 22px;
  align-self: flex-start;
  background-color: #ffffff;
  border-radius: 35px;
  width: 360px;

  text-align: center;
}

.group_3 {
  margin-left: 28px;
  margin-right: 24px;
  line-height: 25px;
}

.font_2 {
  font-size: 26px;
  
  color: #000;
}

.text_3 {
  line-height: 25px;
}

.text_4 {
  font-weight: 700;
  line-height: 21px;
}

.group_4 {
  padding: 315px 0 16px;
  position: relative;
}

.image_2 {
  width: 547px;
  height: 127px;
}

.text_5 {
  color: #000;
  font-size: 28px;
  font-family: OPPOSans;
  line-height: 28px;
  position: absolute;
  left: 245px;
  bottom: 0;
}

.image {
  width: 368px;
  height: 400px;
  position: absolute;
  left: 102px;
  top: 0;
}
</style>